import React from "react"

const Parallax2 = ({ heading, para, isRight, para3, para4 }) => {
  return (
    <div className={`parallax2 ${para3 ? "para3" : para4 && "para4"}`}>
      <div className={`container text-white ${isRight && "det-right"}`}>
        <h1>{heading}</h1>
        <p>{para}</p>
      </div>
    </div>
  )
}

export default Parallax2
