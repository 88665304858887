import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/Layout"
import Parallax2 from "../components/Parallax2"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
// import vid2 from "../assets/videos/vid2.mp4"

const WhoWeAre = ({ data }) => {
  const { strapiWhoWeArePage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Who We Are" />
      <VideoBanner
        heading={info.Banner_heading}
        para={info.Banner_description}
        vid={info.Banner_video.url}
      />
      <Container className="my-5">
        <h1>{info.info_heading}</h1>
        <p className="h5 my-3">{info.info_description}</p>
      </Container>
      <Parallax2
        heading={info.info_heading1}
        para={info.info_description1}
        isRight
        para3
      />
      <Parallax2
        heading={info.info_heading2}
        para={info.info_description2}
        para4
      />
      <Container className="my-5">
        <h1>{info.more_info_heasding}</h1>
        <p className="h5 my-3">{info.more_info_description}</p>
        <h2 className="my-5 text-danger text-center">
          Incumbency + Accomplishment
        </h2>
        <div className="d-flex justify-content-evenly flex-column flex-lg-row text-center">
          <h5>+ We love what we do</h5>
          <h5>+ We always look for breakthroughs</h5>
          <h5>+ We focus on unique techniques.</h5>
        </div>
        <h2 className="my-5 text-secondary text-center">
          We are here to support you & solve your concerns.
        </h2>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiWhoWeArePage {
      Banner_video {
        url
      }
      Banner_heading
      Banner_description
      info_heading
      info_description
      info_heading1
      info_description1
      info_heading2
      info_description2
      more_info_heasding
      more_info_description
    }
  }
`

export default WhoWeAre
